export const createAndAddCssLink = (linkType: 'font' | 'theme', lookupCode: string, cssRef: string) => {
  const existingLink = document.querySelector(`#${linkType}-declaration`) as HTMLLinkElement;

  // If the link exists and has the same href, no need to replace it
  if (existingLink && existingLink.href === cssRef) {
    return;
  }

  // Remove existing link if it exists (and is different)
  if (existingLink) {
    document.head.removeChild(existingLink);
  }

  const link = document.createElement('link');
  link.setAttribute('data-testid', `${linkType}-${lookupCode}-link`);
  link.setAttribute('id', `${linkType}-declaration`);
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = cssRef;

  link.onerror = () => {
    // Remove failed link
    document.head.removeChild(link);

    // Create new link with fallback
    const fallbackLink = document.createElement('link');
    fallbackLink.setAttribute('data-testid', `${linkType}-kaboodle-link`);
    fallbackLink.setAttribute('id', `${linkType}-declaration`);
    fallbackLink.rel = 'stylesheet';
    fallbackLink.type = 'text/css';

    // Set appropriate fallback URL based on type
    if (linkType === 'theme') {
      fallbackLink.href = `${cssRef.split('/BP/')[0]}/BP/44/theme.css`;
    }

    document.head.appendChild(fallbackLink);
  };
  document.head.appendChild(link);
};
