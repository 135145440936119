import { LegDetails, generateTransportBasketDisplayStrings } from './TransportCartItem.helper';

import { BasketItemWrapper } from '../BasketItemWrapper';
import { BasketTransportItem } from '@components/Basket/BasketContent/BasketContent.types';
import { EngineTitle } from '../EngineTitle';
import { Typography } from '@kaboodle-solutions/design-library';
import { calculateLineTotalByCount } from '@components/Basket/BasketContent/BasketContent.helper';
import { getPropertiesFromCartItem } from '@components/Basket/BasketContent/BasketCartItem.helper';
import styles from '../basketContent.module.scss';
import transportStyle from './TransportCartItem.module.scss';
import useCurrencyFormatter from '@src/hooks/useCurrencyFormatter';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface TransportCartItemProps {
  basketMutationStatus?: string;
  engineTitle: string;
  index: number;
  isRemoving?: boolean;
  item: BasketTransportItem;
  mode: 'basket' | 'checkout' | 'completed' | undefined;
  onEngineNameClick: () => void;
  removeItem?: (item: BasketTransportItem) => Promise<void>;
}

export const TransportCartItem = ({
  basketMutationStatus,
  engineTitle,
  index,
  isRemoving = false,
  item,
  mode,
  onEngineNameClick,
  removeItem,
}: TransportCartItemProps) => {
  const { t } = useTranslation();
  const { format } = useCurrencyFormatter();

  const { count, name, price, irremovable } = getPropertiesFromCartItem(item);

  const itemTotal = useMemo(
    () => (price?.value ? format(calculateLineTotalByCount({ count, price: price?.value })) : null),
    [format, count, price]
  );

  const renderTransportLegs = (item: BasketTransportItem) => {
    const outboundLeg = item.legs.find((leg) => leg.type === 'OUTBOUND');
    const returnLeg = item.legs.find((leg) => leg.type === 'RETURN');
    const outboundDetails = generateTransportBasketDisplayStrings(outboundLeg, t);
    const returnDetails = generateTransportBasketDisplayStrings(returnLeg, t);

    return (
      <div className={styles.basketItem_rowDesc}>
        <div className={transportStyle.transportLegDetails}>
          {outboundLeg && <LegDetails details={outboundDetails} />}
          {returnLeg && outboundLeg && <hr id="transport-leg-divider" />}
          {returnLeg && <LegDetails details={returnDetails} />}
        </div>
      </div>
    );
  };

  const handleRemoveItem = () => removeItem?.(item);

  const showRemoveButton = mode === 'basket' && !irremovable;
  const isDisabledRemoveButton = basketMutationStatus === 'pending';

  return (
    <>
      <EngineTitle title={engineTitle} index={index} mode={mode} onTitleClick={onEngineNameClick} />
      <Typography tag="span" isBold customClass={styles.packageTitle}>
        {name}
      </Typography>
      <BasketItemWrapper
        showRemoveButton={showRemoveButton}
        isDisabledRemoveButton={isDisabledRemoveButton}
        isRemoving={isRemoving}
        onRemove={handleRemoveItem}
        customClass={transportStyle.transportCartItem}
      >
        <div className={styles.basketItem_rowDesc}>
          <Typography tag="span" customClass={`${styles.basketItem_name} ${transportStyle.transportItem}`}>
            <Typography tag="span" isBold customClass={styles.basketItem_Count}>
              {count}x
            </Typography>{' '}
            <Typography customClass={styles.basketItem_name_displayName}>{renderTransportLegs(item)}</Typography>
          </Typography>
          <Typography tag="span" customClass={styles.basketItem_price}>
            {itemTotal}
          </Typography>
        </div>
      </BasketItemWrapper>
    </>
  );
};
