export function groupBy<T>(items: T[], keyFn: (item: T) => string): { [key: string]: T[] } {
  return items.reduce((acc, item) => {
    const key = keyFn(item);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {} as Record<string, T[]>);
}
