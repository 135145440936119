import { Accommodation, AccommodationGroup, AccommodationRoomWithExtras } from '@src/interfaces/accommodation';
import {
  BasketAccommodationItem,
  BasketExtraItem,
  BasketTicketItem,
  BasketTransportItem,
} from '@src/components/Basket/BasketContent/BasketContent.types';
import { Extra, Price } from '@src/interfaces/extras';
import { ITicketsEngineResponse, Tickets } from '@src/api/useTickets';
import {
  generateBasketAccommodationItem,
  generateBasketExtrasItem,
  generateBasketTicketsItem,
} from '@src/components/Basket/BasketContent/BasketContent.helper';
/* eslint-disable camelcase */
import tracking, { EccomerceItemUnformatted } from './';

import { BaseBasket } from '@src/api/useBasket/useBasket.types';
import { BasketTransport, KapiAccommodation } from '@src/interfaces/engineBasketPutRequest';
import { PaymentIntentResponse } from '@src/interfaces/paymentIntent';
import { formatTrackingPrice } from '../currencyFormatter/currencyFormatter';
import { PackageTransportStop, TransportType } from '@src/interfaces/transport';

export interface TrackingTicket extends Tickets {
  listName?: string;
  listId?: number;
}

export interface TrackingExtra extends Extra {
  addedOptionId?: number;
  addedQty?: number;
}

export type TrackingEvent =
  | 'add_to_cart'
  | 'remove_from_cart'
  | 'ticket_reload'
  | 'basket_update'
  | 'checkout'
  | 'purchase'
  | 'cart_reload'
  | 'basket_continue'
  | 'basket_continue_customer_details';

class ECommTracking {
  tickets = {
    sendIncrementDecrementEvent(ticket: Tickets | undefined, type: 'add' | 'remove') {
      if (!ticket) return;

      tracking.sendEvent({
        event: `ticket_${type}`,
        ticket_name: ticket?.name,
        ticket_face_value: formatTrackingPrice((ticket?.price?.value || 0) + (ticket?.price?.bookingFee || 0)),
      });
    },
    sendViewItemListEvent(ticketsResponse: ITicketsEngineResponse[]) {
      let idx = 0;
      const items = ticketsResponse.flatMap(({ tickets, name: ticketTypeName, id: ticketTypeId }) =>
        tickets.map(({ id, name, priceClass, price }) => {
          const index = idx;
          idx++;
          return {
            item_id: id,
            item_name: name,
            item_list_name: ticketTypeName,
            item_list_id: ticketTypeId,
            item_brand: tracking.baseAnalytics.package_id,
            affiliation: tracking.baseAnalytics.client_name,
            item_category: 'Tickets',
            item_category2: ticketTypeName,
            item_category3: priceClass || '',
            price: price.value + price.bookingFee || 0,
            index,
            quantity: 1,
          };
        })
      );

      tracking.sendEcommerceEvent(
        {
          event: 'view_item_list',
          ecommerce: {
            items: items.map((item) => ({
              ...item,
              price: formatTrackingPrice(item.price),
            })),
            currency: 'GBP',
            value: formatTrackingPrice(items.reduce((acc, item) => acc + item.price, 0)),
          },
          others: {},
        },
        { canRepeat: false }
      );
    },
    sendAddToCartEvent(tickets: TrackingTicket[], addedTicketIdAndQuantityState: { [key: number]: number }) {
      const items = tickets
        .map(({ id, name, priceClass, price, listId, listName }, index) => {
          const quantity = addedTicketIdAndQuantityState[id];
          if (!quantity) return null;
          return {
            quantity,
            item_id: id,
            item_name: name,
            item_list_name: listName,
            item_list_id: listId,
            item_brand: tracking.baseAnalytics.package_id,
            affiliation: tracking.baseAnalytics.client_name,
            item_category: 'Tickets',
            item_category2: listName,
            item_category3: priceClass || '',
            price: price.value + price.bookingFee || 0,
            index,
          };
        })
        .filter((item): item is NonNullable<typeof item> => item !== null);

      tracking.sendEcommerceEvent({
        event: 'add_to_cart',
        ecommerce: {
          items: items.map((item) => ({
            ...item,
            price: formatTrackingPrice(item?.price),
          })),
          currency: 'GBP',
          value: formatTrackingPrice(items.reduce((acc, item) => acc + item.price * item.quantity, 0)),
        },
        others: {},
      });
    },
    sendRemoveFromCartEvent(item: BasketTicketItem) {
      const eventData = generateBasketEcommItem.tickets<BasketTicketItem>(item);

      tracking.sendEcommerceEvent({
        event: 'remove_from_cart',
        ecommerce: {
          currency: 'GBP',
          value: formatTrackingPrice(eventData.price * (eventData.quantity || 1)),
          items: [
            {
              ...eventData,
              price: formatTrackingPrice(eventData.price),
            },
          ],
        },
        others: {},
      });
    },
  };

  extras = {
    sendViewItemListEvent(extras: Extra[]) {
      const items = extras.map((extra, index) => ({
        item_id: extra.id,
        item_name: extra.name,
        affiliation: tracking.baseAnalytics.client_name,
        currency: 'GBP',
        index: index,
        item_brand: 'package_id',
        item_category: extra.isMandatory ? 'Mandatory' : 'Optional',
        item_category2: extra.extraType.name || '',
        item_list_id: 'routes, id',
        item_list_name: 'Extras',
        location_id: tracking.locationId,
        options: extra.groups
          .flatMap((group) => group.options)
          .map(({ id, name, prices, available, freesale, sortOrder, groupId }) => ({
            id,
            name,
            prices,
            available,
            freesale,
            sortOrder,
            groupId,
          })),
        quantity: 1,
      }));

      tracking.sendEcommerceEvent(
        {
          event: 'view_item_list',
          ecommerce: {
            items: items.map((item) => ({
              ...item,
              price: formatTrackingPrice(
                item.options.reduce((acc, option) => acc + option.prices.sellPrice + option.prices.bookingFee, 0)
              ),
            })),
            currency: 'GBP',
            value: formatTrackingPrice(
              items.reduce(
                (acc, item) =>
                  acc +
                  item.options.reduce((acc, option) => acc + option.prices.sellPrice + option.prices.bookingFee, 0) *
                    item.quantity,
                0
              )
            ),
          },
          others: {},
        },
        { canRepeat: false }
      );
    },
    sendAddToCartEvent(addedExtras: TrackingExtra[]) {
      const addedOptionPrices: Price[] = [];
      const ecommObject = {
        currency: 'GBP',
        value: '0',
        items: addedExtras.map((extra, index) => {
          const { groups, addedQty, addedOptionId } = extra;
          const option = groups
            .find(({ options }) => options.find(({ id }) => id === addedOptionId))
            ?.options.find(({ id }) => id === addedOptionId);
          if (option?.prices) {
            addedOptionPrices.push(...Array(addedQty).fill(option.prices));
          }

          return {
            item_id: extra.id,
            item_name: option?.name || '', // Option name
            affiliation: tracking.baseAnalytics.client_name,
            currency: 'GBP',
            index,
            item_brand: tracking.baseAnalytics.package_id,
            item_category: extra.isMandatory ? 'Mandatory' : 'Optional',
            item_category2: extra.extraType.name,
            item_list_id: '',
            item_list_name: 'Extras',
            location_id: tracking.locationId,
            price: formatTrackingPrice(option?.prices?.sellPrice, option?.prices?.bookingFee),
            quantity: addedQty,
          };
        }),
      };
      ecommObject.value = formatTrackingPrice(
        addedOptionPrices.reduce((acc, cur) => acc + cur.sellPrice + cur.bookingFee, 0)
      );

      tracking.sendEcommerceEvent({
        event: 'add_to_cart',
        ecommerce: ecommObject,
        others: {},
      });
    },
    sendRemoveFromCartEvent(item: BasketExtraItem) {
      const eventData = generateBasketEcommItem.extras<BasketExtraItem>(item);

      tracking.sendEcommerceEvent({
        event: 'remove_from_cart',
        ecommerce: {
          currency: 'GBP',
          value: formatTrackingPrice(eventData.price * (eventData.quantity || 1)),
          items: [
            {
              ...eventData,
              price: formatTrackingPrice(eventData.price),
            },
          ],
        },
        others: {},
      });
    },
  };

  accomm = {
    sendViewItemListEvent(accomms: AccommodationGroup[] | Accommodation[]) {
      function checkIsGroup(item: AccommodationGroup | Accommodation): item is AccommodationGroup {
        return 'title' in item;
      }

      const items = accomms.map((item, idx) => {
        const isGroup = checkIsGroup(item);
        return {
          item_id: item.id,
          item_name: isGroup ? item.title : item.name,
          item_list_name: 'Simple Accommodation', // TODO: update once Complex Accomm exists
          item_list_id: isGroup ? item.title : item.name,
          affiliation: tracking.baseAnalytics.client_name || '',
          currency: 'GBP',
          index: idx,
          item_brand: '', // Resort Name,
          item_category: '', // Accomm Type
          item_category2: isGroup ? '' : item.name, // Accomm Name
          item_category3: '', // Room Basis
          item_category4: '', // How many per room
          item_category5: '', // check in/out dates
          location_id: tracking.locationId,
          price: isGroup ? item.fromPrice || 0 : item.fromPrice.value || 0,
          quantity: 1,
        };
      });

      tracking.sendEcommerceEvent(
        {
          event: 'view_item_list',
          ecommerce: {
            items: items.map((item) => ({
              ...item,
              price: formatTrackingPrice(item.price),
            })),
            currency: 'GBP',
            value: formatTrackingPrice(items.reduce((acc, item) => acc + item.price, 0)),
          },
          others: {},
        },
        { canRepeat: false }
      );
    },
    sendViewItemEvent(rooms: AccommodationRoomWithExtras[]) {
      const items = rooms.map((item, idx) => ({
        item_id: item.id,
        item_name: item.name,
        item_list_name: 'Simple Accommodation', // TODO: update once Complex Accomm exists
        item_list_id: item.accommodationId,
        affiliation: tracking.baseAnalytics.client_name || '',
        currency: 'GBP',
        index: idx,
        item_brand: '', // Resort Name,
        item_category: '', // Accomm Type
        item_category2: item.name, // Accomm Name
        item_category3: item.roomTypeBasis, // Room Basis
        item_category4: '', // How many per room
        item_category5: '', // check in/out dates
        location_id: tracking.locationId,
        price: (item.price.value || 0) + (item.price.bookingFee || 0),
        quantity: 1,
      }));

      tracking.sendEcommerceEvent(
        {
          event: 'view_item',
          ecommerce: {
            items: items.map((item) => ({
              ...item,
              price: formatTrackingPrice(item.price),
            })),
            currency: 'GBP',
            value: formatTrackingPrice(items.reduce((acc, item) => acc + item.price, 0)),
          },
          others: {},
        },
        { canRepeat: false }
      );
    },
    sendAddToCartEvent(
      addedRoom: AccommodationRoomWithExtras,
      addedState: KapiAccommodation[],
      parentAccommodation: Accommodation | undefined
    ) {
      const findExtra = (optionId: number) => {
        for (const extra of addedRoom.extras) {
          const group = extra.groups.find((group) => group.options.some((option) => option.id === optionId));
          if (group) {
            const option = group.options.find((option) => option.id === optionId);
            return { extra, option };
          }
        }
      };

      const extras = addedState
        .flatMap(({ extras }) => extras)
        .reduce((acc, { optionId }) => {
          const existingItem = acc.find((item) => item?.option?.id === optionId);
          if (existingItem) {
            existingItem.quantity += 1;
          } else {
            const foundExtra = findExtra(optionId);
            if (foundExtra) {
              acc.push({ ...foundExtra, quantity: 1 });
            }
          }
          return acc;
        }, [] as Array<ReturnType<typeof findExtra> & { quantity: number }>);

      const ecommRoom = {
        item_id: addedRoom.accommodationId,
        item_name: parentAccommodation?.name || addedRoom.name,
        item_list_name: 'Simple Accommodation', // TODO: update once Complex Accomm exists
        item_list_id: parentAccommodation?.name || addedRoom.name,
        affiliation: tracking.baseAnalytics.client_name || '',
        currency: 'GBP',
        item_brand: '', // Resort Name,
        item_category: '', // Accomm Type
        item_category2: addedRoom.roomTypeDescription, // Extra Name
        item_category3: addedRoom.roomTypeBasis, // Room Basis
        item_category4: '', // How many per room
        item_category5: '', // check in/out dates
        location_id: tracking.locationId,
        price: (addedRoom.price.value || 0) + (addedRoom.price.bookingFee || 0),
        quantity: addedState.length,
      };

      const { item_category4: _, item_category5: __, ...common } = ecommRoom;
      const ecommExtras = extras.map(({ extra, option, quantity }) => {
        return {
          ...common,
          item_id: extra.id,
          item_name: option?.name || '',
          item_category: 'Accommodation Extra', // Accomm Type
          item_category2: extra.name, // Extra Name
          price: (option?.prices.sellPrice || 0) + (option?.prices.bookingFee || 0),
          quantity,
        };
      });

      const items = [ecommRoom, ...ecommExtras];

      tracking.sendEcommerceEvent({
        event: 'add_to_cart',
        ecommerce: {
          items: items.map((item) => ({
            ...item,
            price: formatTrackingPrice(item.price),
          })),
          currency: 'GBP',
          value: formatTrackingPrice(items.reduce((acc, item) => acc + item.price * item.quantity, 0)),
        },
        others: {},
      });
    },
    sendRemoveFromCartEvent(item: BasketAccommodationItem) {
      const items = generateBasketEcommItem.accomm<BasketAccommodationItem>(item);

      tracking.sendEcommerceEvent({
        event: 'remove_from_cart',
        ecommerce: {
          items: items.map((item) => ({
            ...item,
            price: formatTrackingPrice(item.price),
          })),
          currency: 'GBP',
          value: formatTrackingPrice(items.reduce((acc, item) => acc + item.price * (item.quantity || 1), 0)),
        },
        others: {},
      });
    },
  };

  transport = {
    sendViewItemListEvent(params: {
      stops: PackageTransportStop[];
      selectedType: TransportType;
      departureName: string;
      arrivalName: string;
      customers?: number;
    }) {
      const items = params.stops.map((stop, index) => {
        const route = params.selectedType.routes.find((route) => route.id === stop.routeId);
        const routeName = route?.name || '';
        return {
          item_id: stop.stopId,
          item_name: `${params.departureName} -> ${params.arrivalName}`,
          affiliation: tracking.baseAnalytics.client_name || '',
          currency: 'GBP',
          index,
          item_brand: routeName,
          item_category: params.selectedType.name,
          item_category2: params.departureName,
          item_category3: stop.date,
          item_list_id: stop.routeId,
          item_list_name: routeName,
          item_variant: stop.direction,
          location_id: tracking.locationId,
          price: (stop.price?.value || 0) + (stop.price?.bookingFee || 0),
          quantity: params.customers || 1,
        };
      });

      tracking.sendEcommerceEvent(
        {
          event: 'view_item_list',
          ecommerce: {
            items: items.map((item) => ({
              ...item,
              price: formatTrackingPrice(item.price),
            })),
            currency: 'GBP',
            value: formatTrackingPrice(items.reduce((acc, item) => acc + item.price, 0)),
          },
          others: {},
        },
        { canRepeat: false }
      );
    },

    sendAddToCartEvent(transport: BasketTransport, quantity: number, selectedType: TransportType) {
      const route = selectedType.routes.find((r) => r.id === transport.routeId);
      const items = transport.legs.map((leg, index) => ({
        item_id: leg.stopId,
        item_name: `${leg.departureName} -> ${leg.arrivalName}`,
        affiliation: tracking.baseAnalytics.client_name || '',
        currency: 'GBP',
        index,
        item_brand: route?.name,
        item_category: selectedType.name ?? '',
        item_category2: leg.departureName,
        item_category3: leg.date,
        item_list_id: route?.id,
        item_list_name: route?.name,
        item_variant: leg.type,
        location_id: tracking.locationId,
        price: (leg.price?.value || 0) + (leg.price?.bookingFee || 0),
        quantity,
      }));

      tracking.sendEcommerceEvent({
        event: 'add_to_cart',
        ecommerce: {
          items: items.map((item) => ({
            ...item,
            price: formatTrackingPrice(item.price),
          })),
          currency: 'GBP',
          value: formatTrackingPrice(items.reduce((acc, item) => acc + item.price * item.quantity, 0)),
        },
        others: {},
      });
    },

    sendRemoveFromCartEvent(transport: BasketTransportItem) {
      const items = transport.legs.map((leg, index) => {
        return {
          item_id: leg.stopId,
          item_name: `${leg.departureLocation} -> ${leg.arrivalLocation}`,
          affiliation: tracking.baseAnalytics.client_name || '',
          currency: 'GBP',
          index,
          item_brand: transport.routeName,
          item_category: transport.type ?? '',
          item_category2: leg.departureLocation,
          item_category3: leg.date ?? '',
          item_list_id: transport.routeId,
          item_list_name: transport.routeName,
          item_variant: leg.type,
          location_id: tracking.locationId,
          price: (leg.price?.value || 0) + (leg.price?.bookingFee || 0),
          quantity: transport.count || 1,
        };
      });

      tracking.sendEcommerceEvent({
        event: 'remove_from_cart',
        ecommerce: {
          items: items.map((item) => ({
            ...item,
            price: formatTrackingPrice(item.price),
          })),
          currency: 'GBP',
          value: formatTrackingPrice(items.reduce((acc, item) => acc + item.price * item.quantity, 0)),
        },
        others: {},
      });
    },

    sendTransportEvent(selectedType: TransportType, eventType: 'add' | 'remove') {
      tracking.sendEcommerceEvent({
        event: `transport_${eventType}`,
        ecommerce: {
          items: [
            {
              item_name: selectedType.name,
              item_id: selectedType.handle,
              price: '',
            },
          ],
        },
        others: {},
      });
    },
  };

  customerDetails = {
    sendProtectionPlanCartEvent(eventType: TrackingEvent, price: number) {
      const value = formatTrackingPrice(price);
      tracking.sendEcommerceEvent({
        event: eventType,
        ecommerce: {
          currency: 'GBP',
          value,
          items: [
            {
              item_id: 'Protection Plan',
              item_name: 'Protection Plan',
              affiliation: tracking.baseAnalytics.client_name || '',
              currency: 'GBP',
              index: 0,
              item_brand: tracking.baseAnalytics.package_id,
              item_category: 'Protection Plan',
              location_id: tracking.locationId,
              price: value,
              quantity: 1,
            },
          ],
        },
        others: {},
      });
    },
  };
  purchase = {
    sendPurchaseCartEvent(basket: BaseBasket, bookingRef: string) {
      const [tickets, extras, accomm] = [
        generateBasketTicketsItem(basket?.tickets),
        generateBasketExtrasItem(basket?.extras),
        generateBasketAccommodationItem(basket?.accommodations),
      ];

      const ecommItems = [
        ...generateBasketEcommItem.tickets<typeof tickets>(tickets),
        ...generateBasketEcommItem.extras<typeof extras>(extras),
        ...generateBasketEcommItem.accomm<typeof accomm>(accomm),
      ];
      const event = 'purchase';
      tracking.sendEcommerceEvent(
        {
          event,
          ecommerce: {
            transaction_id: bookingRef,
            currency: basket.currencyCode || '',
            value: formatTrackingPrice(basket.calculated?.total?.value) || 0,
            items: ecommItems.map((item) => ({
              ...item,
              price: formatTrackingPrice(item.price),
            })),
          },
          others: {},
        },
        { trackOnceBasedOnValue: `${event},${bookingRef}` }
      );
    },
    sendBookingSuccessCartEvent(
      basket: BaseBasket,
      bookingRef: string,
      paymentIntent: PaymentIntentResponse | null | undefined
    ) {
      const event = 'booking_success';
      tracking.sendEvent(
        {
          event,
          transaction_id: bookingRef,
          full_total: formatTrackingPrice(basket?.calculated?.total.value ?? 0),
          total: formatTrackingPrice(basket?.calculated?.total.value ?? 0),
          paid_total:
            paymentIntent?.status === 'succeeded' && (paymentIntent?.amount ?? 0) > 0
              ? formatTrackingPrice(paymentIntent?.amount)
              : 0,
          pax: basket.tickets?.length || 0,
          ticket_data:
            basket?.tickets
              ?.map((ticket) => {
                return `${ticket.name},${formatTrackingPrice(ticket.price.value + ticket.price.bookingFee)}`;
              })
              .join('/') || '',
        },
        { trackOnceBasedOnValue: `${event},${bookingRef}` }
      );
    },
    sendNoSessionPaymentSuccess(
      bookingRef: string,
      noSessionPaymentDisplay: {
        currencyCode: string;
        value: number;
      }
    ) {
      const event = 'no_session_booking_success';
      tracking.sendEvent(
        {
          event,
          transaction_id: bookingRef,
          paid_today: formatTrackingPrice(noSessionPaymentDisplay.value ?? 0),
        },
        { trackOnceBasedOnValue: `${event},${bookingRef}` }
      );
    },
  };
}

const eCommTracking = new ECommTracking();
export default eCommTracking;

type EcommItemReturnType<T, U> = T extends U ? EccomerceItemUnformatted : EccomerceItemUnformatted[];

const generateBasketEcommItem = {
  tickets: <T>(
    tickets: T extends BasketTicketItem ? BasketTicketItem : BasketTicketItem[]
  ): EcommItemReturnType<T, BasketTicketItem> => {
    const toEcommItem = (ticket: BasketTicketItem): EccomerceItemUnformatted => ({
      item_id: ticket.typeId,
      item_name: ticket.name,
      affiliation: tracking.baseAnalytics.client_name,
      currency: 'GBP',
      index: 0,
      item_brand: tracking.baseAnalytics.package_id,
      item_category: 'Tickets',
      item_category2: ticket.eventName,
      item_category3: ticket.class,
      item_list_id: ticket.eventId,
      item_list_name: ticket.eventName,
      price: (ticket.price.value || 0) + (ticket.price.bookingFee || 0),
      quantity: ticket.count,
    });

    if (Array.isArray(tickets)) {
      return tickets.map(toEcommItem) as EcommItemReturnType<T, BasketTicketItem>;
    } else {
      return toEcommItem(tickets) as EcommItemReturnType<T, BasketTicketItem>;
    }
  },
  extras: <T>(
    tickets: T extends BasketExtraItem ? BasketExtraItem : BasketExtraItem[]
  ): EcommItemReturnType<T, BasketExtraItem> => {
    const toEcommItem = (extra: BasketExtraItem): EccomerceItemUnformatted => ({
      item_id: extra.extraId,
      item_name: extra.optionName,
      affiliation: tracking.baseAnalytics.client_name,
      currency: 'GBP',
      index: 0,
      item_category: extra.irremovable ? 'Mandatory' : 'Optional',
      item_category2: '',
      item_list_name: 'Extras',
      location_id: tracking.locationId,
      price: (extra.price?.value || 0) + (extra?.price?.bookingFee || 0),
      quantity: extra.count || 1,
    });

    if (Array.isArray(tickets)) {
      return tickets.map(toEcommItem) as EcommItemReturnType<T, BasketExtraItem>;
    } else {
      return toEcommItem(tickets) as EcommItemReturnType<T, BasketExtraItem>;
    }
  },
  accomm: <T>(accomm: T extends BasketAccommodationItem ? BasketAccommodationItem : BasketAccommodationItem[]) => {
    const toEcommItem = (item: BasketAccommodationItem): EccomerceItemUnformatted[] => {
      const eventData = {
        item_id: item.accommodationId,
        item_name: item.accommodationName,
        item_list_name: 'Simple Accommodation', // TODO: update once Complext Accomm exists
        item_list_id: item.accommodationName,
        affiliation: tracking.baseAnalytics.client_name || '',
        currency: 'GBP',
        item_brand: '', // Resort Name,
        item_category: '', // Accomm Type
        item_category2: item.roomName,
        item_category3: '', // Room Basis - Missing on Basket Item
        item_category4: '', // How many per room
        item_category5: '', // check in/out dates
        location_id: tracking.locationId,
        price: (item.price.value || 0) + (item.price.bookingFee || 0),
        quantity: item.count,
      };

      const { item_category4: _, item_category5: __, ...common } = eventData;
      const ecommExtras = item.extras.map((extra) => ({
        ...common,
        item_id: extra.extraId,
        item_name: extra.optionName,
        item_category: 'Accommodation Extra',
        item_category2: extra.extraName,
        price: (extra.price.value || 0) + (extra.price.bookingFee || 0),
        quantity: extra.count,
      }));

      return [eventData, ...ecommExtras];
    };

    if (Array.isArray(accomm)) {
      return accomm.flatMap(toEcommItem);
    } else {
      return toEcommItem(accomm);
    }
  },
};
