import { BPEnginesWithItemsHandles, EngineHandles, EngineNames } from '@src/constants';

import { BaseBasket } from '@src/api/useBasket/useBasket.types';
import { InitResponse } from '@src/api/useInit';
import { type Location } from 'react-router-dom';
import { create } from 'zustand';
import { createStepsArray } from './useEngineNavigationStore.helper';

export type Step = {
  linkUrl: string;
  linkTitleKey: string;
  mandatory: boolean;
  engineName: EngineNames;
  engineHandle: EngineHandles;
  enableNavigation: boolean;
  valid: boolean;
  basketChecked: boolean;
};

export type Steps = Step[] | [];

export type EngineNavigationStoreStore = {
  steps: Steps;
  lastNavigableStep: Step | null;
  // Usually Engines before the login route
  lastNavigableEngineWithItems: Step | null;

  location: Location<unknown> | null;
  setLocation: (newLocation: Location<unknown>) => void;

  generateSteps: (
    basketData: BaseBasket | null | undefined,
    engines: InitResponse['engines'] | null,
    isBasketFetched?: boolean
  ) => void;

  _getCurrentStep: (location: Location<unknown> | null, steps: Steps) => Step | null;
  currentStep: Step | null;

  _getNextStep: (location: Location<unknown> | null, steps: Steps) => Step | null;
  nextStep: Step | null;

  forceEnabledContinueButton: boolean;
  /**
   * Do not use this function directly from the store. Refer to useConfigureNavigationOverrides for it.
   */
  _setForceEnabledContinueButton: (newForceEnabledContinueButton: boolean) => void;

  addToContinueButtonCallback: () => void;
  /**
   * Do not use this function directly from the store. Refer to useConfigureNavigationOverrides for it.
   */
  _setAddToContinueButtonCallback: (newContinueButtonCallback: () => void) => void;

  /**
   * Default button callback is configured in useNavigationController for now. Starts as null
   */
  continueButtonCallback: () => void;

  /**
   * This controls if we want to completely skip default continue button behavior and just run the new function that was set as addToContinueButtonCallback
   */
  skipDefaultContinueButtonCallback: boolean;
  _setSkipDefaultContinueButtonCallback: (newSkipDefaultContinueButtonCallback: boolean) => void;

  // Navigate function is being configured in useNavigationController. Currently uses useNavigateWithQueryParams
  navigateFunction: ((path: string | number, includeAccessCode?: boolean) => void) | null;

  navigateToNextStep: () => void;
  setNavigateFunction: (newNavigateFunction: (path: string | number, includeAccessCode?: boolean) => void) => void;

  shouldDisplayContinueButton: boolean;
  _setShouldDisplayContinueButton: (newValue: boolean) => void;
};

export const useEngineNavigationStore = create<EngineNavigationStoreStore>()((set, get) => ({
  steps: [],
  lastNavigableStep: null,
  lastNavigableEngineWithItems: null,

  location: null,
  setLocation: (newLocation) => {
    const { location, steps, _getNextStep, _getCurrentStep } = get();
    if (location !== newLocation) {
      set({
        location: newLocation,
        nextStep: _getNextStep(newLocation, steps),
        currentStep: _getCurrentStep(newLocation, steps),
      });
    }
  },

  generateSteps: (basketData, engines, isBasketFetched = true) => {
    const { location, _getCurrentStep, _getNextStep } = get();
    if (!engines) {
      return set(() => ({ steps: [] }));
    }

    const stages = createStepsArray(basketData, engines, isBasketFetched);
    const lastNavigableStep = stages.findLast((step) => step.enableNavigation);
    const lastNavigableEngineWithItems = stages.findLast(
      (step) => step.enableNavigation && BPEnginesWithItemsHandles.includes(step.engineHandle)
    );

    return set(() => ({
      steps: stages,
      lastNavigableStep,
      lastNavigableEngineWithItems,
      currentStep: _getCurrentStep(location, stages),
      nextStep: _getNextStep(location, stages),
    }));
  },
  _getCurrentStep: (location, steps) => {
    if (steps.length && location) {
      return steps.find((step) => location.pathname.includes(step.linkUrl)) ?? null;
    }

    return null;
  },
  currentStep: null,

  _getNextStep: (location, steps) => {
    if (steps.length && location) {
      const currentStepIndex = steps.findIndex((step) => location.pathname.includes(step.linkUrl));

      if (currentStepIndex < 0) return null;

      if (steps[currentStepIndex + 1]) {
        return steps[currentStepIndex + 1];
      }
    }

    return null;
  },
  nextStep: null,

  forceEnabledContinueButton: false,
  _setForceEnabledContinueButton: (newForceEnabledContinueButton) => {
    if (get().forceEnabledContinueButton !== newForceEnabledContinueButton) {
      set({ forceEnabledContinueButton: newForceEnabledContinueButton });
    }
  },

  addToContinueButtonCallback: Function,
  _setAddToContinueButtonCallback: (newContinueButtonCallback) => {
    const { addToContinueButtonCallback } = get();

    if (addToContinueButtonCallback.toString() !== newContinueButtonCallback.toString()) {
      set({ addToContinueButtonCallback: newContinueButtonCallback });
    }
  },

  skipDefaultContinueButtonCallback: false,
  _setSkipDefaultContinueButtonCallback: (newSkipDefaultContinueButtonCallback) => {
    if (get().skipDefaultContinueButtonCallback !== newSkipDefaultContinueButtonCallback) {
      set({ skipDefaultContinueButtonCallback: newSkipDefaultContinueButtonCallback });
    }
  },

  navigateFunction: null,
  navigateToNextStep: () => {
    const { navigateFunction, nextStep } = get();

    if (navigateFunction && nextStep) {
      window.scrollTo(0, 0);
      navigateFunction(nextStep.linkUrl);
    }
  },
  setNavigateFunction: (newNavigateFunction) => {
    if (get().navigateFunction?.toString() !== newNavigateFunction.toString()) {
      set({ navigateFunction: newNavigateFunction });
    }
  },

  continueButtonCallback: () => {
    const additionalButtonCallback = get().addToContinueButtonCallback;
    if (additionalButtonCallback) {
      additionalButtonCallback();
    }

    if (!get().skipDefaultContinueButtonCallback) {
      const { navigateFunction, nextStep } = get();

      if (nextStep && navigateFunction) {
        navigateFunction(nextStep.linkUrl);
      }
    }
  },

  shouldDisplayContinueButton: true,
  _setShouldDisplayContinueButton: (newValue) => {
    const { shouldDisplayContinueButton } = get();
    if (newValue !== shouldDisplayContinueButton) {
      set({ shouldDisplayContinueButton: newValue });
    }
  },
}));
