import {
  ALLOW_LIST_ADDRESS_VALIDATION_ERROR_REGEX,
  ALLOW_LIST_DOMAIN_VALIDATION_ERROR_REGEX,
  CONTNUE_ONLY_ERROR_HANDLES,
  INVALID_EMAIL_ERROR,
} from '@src/constants/errors';
import { type Id, toast } from 'react-toastify';
import { KapiBasketEngineErrorHandles, KapiEngineHandlesOptions } from '@src/interfaces/engineData';
import { toastNotification, useToastStore } from '@src/lib/toast/toast';

import { BPEngineHandlesOptions } from '@src/constants';
import { BaseBasket } from '@src/api/useBasket/useBasket.types';
import { ServiceMessageVariants } from '@kaboodle-solutions/design-library';

type CombinedEngineHandles = `${KapiEngineHandlesOptions}` | `${BPEngineHandlesOptions}`;

const allowedValidationErrors: {
  [key in CombinedEngineHandles]?: { regex?: RegExp; handle?: KapiBasketEngineErrorHandles; override?: string }[];
} = {
  [KapiEngineHandlesOptions.CUSTOMER_DETAILS]: [
    {
      regex: ALLOW_LIST_ADDRESS_VALIDATION_ERROR_REGEX,
      override: INVALID_EMAIL_ERROR,
    },
    {
      regex: ALLOW_LIST_DOMAIN_VALIDATION_ERROR_REGEX,
      override: INVALID_EMAIL_ERROR,
    },
  ],
  [KapiEngineHandlesOptions.BASKET]: [
    {
      handle: KapiBasketEngineErrorHandles.NO_ITEMS_IN_BASKET,
    },
  ],
};

interface Props {
  basket: BaseBasket | null;
  engine: CombinedEngineHandles | CombinedEngineHandles[] | undefined | null;
  sideEffects?: { onError?: () => void; onValidationError?: () => void };
  trigger?: 'continue';
}

const showToastNotification = (
  toastIds: Id[],
  message: string,
  variant: ServiceMessageVariants,
  testId: string,
  group: string
) => {
  const { id } = toastNotification({
    testId,
    toastId: message,
    content: message,
    variant,
    autoClose: false,
    group,
  });
  toastIds.push(id);
};

const shouldShow = (handle: KapiBasketEngineErrorHandles, trigger: 'continue' | undefined) => {
  return (
    trigger === 'continue' || // Show all relevant error types on continue attempt
    (trigger !== 'continue' && !CONTNUE_ONLY_ERROR_HANDLES.includes(handle))
  );
};

/**
 * Displays basket-related errors based on the provided basket, engine, and trigger.
 * Optionally triggers side effects such as error handling or validation error handling.
 *
 * @param {Object} props - The function's props.
 * @param {BaseBasket | null} props.basket - The basket object to check for errors. If null, no basket errors will be processed.
 * @param {CombinedEngineHandles | CombinedEngineHandles[] | undefined | null} props.engine  - The engine options used for error handling. If undefined, default engine behavior is used.
 * @param {Object} [props.sideEffects]  - Optional side effects to handle specific scenarios.
 * @param {Function} [props.sideEffects.onError]- Callback function to execute when a general error occurs.
 * @param {Function} [props.sideEffects.onValidationError] - Callback function to execute when a validation error occurs.
 * @param {'continue'} [props.trigger] - Specifies the type of trigger action. Can be either 'update' or 'continue'.
 *
 @returns {void}
 */

export default function displayBasketErrors({ basket, engine, sideEffects, trigger }: Props) {
  if (!engine || !engine.length || !basket) return;

  const toastIds: Id[] = [];
  const { onError, onValidationError } = sideEffects || {};

  // Handle basket errors
  if (basket.errors) {
    basket.errors.forEach(({ message, handle }) => {
      if (shouldShow(handle, trigger)) {
        showToastNotification(
          toastIds,
          message,
          ServiceMessageVariants.Error,
          'engine-state-basket-error-notification',
          'basket'
        );
      }
    });
    onError?.();
    // If we have any errors, we won't display toasts for validation errors
    return;
  }

  const engines = Array.isArray(engine) ? engine : [engine];

  // Always include BASKET level errors
  engines.push(KapiEngineHandlesOptions.BASKET);

  // For whitelisted emails current setup, couple CUSTOMER_DETAILS and LOGIN engines
  if (engines.includes(KapiEngineHandlesOptions.LOGIN)) engines.push(KapiEngineHandlesOptions.CUSTOMER_DETAILS);
  if (engines.includes(KapiEngineHandlesOptions.CUSTOMER_DETAILS)) engines.push(KapiEngineHandlesOptions.LOGIN);

  const validationErrorsForThisEngine = basket.validationErrors?.filter((error) =>
    engines.includes(error.engine as CombinedEngineHandles)
  );

  if (validationErrorsForThisEngine) {
    validationErrorsForThisEngine.forEach(({ message, engine: errorEngine, handle }) => {
      if (shouldShow(handle, trigger)) {
        const allowList = allowedValidationErrors[errorEngine];
        const allowedError = allowList?.find((config) =>
          config?.regex ? config.regex.test(message) : true && config.handle ? config.handle === handle : true
        );

        // Show toast if the error is allowed or if there's no allow list
        if (!allowList || allowedError) {
          showToastNotification(
            toastIds,
            allowedError?.override || message,
            ServiceMessageVariants.Info,
            'engine-state-basket-info-notification',
            'basket'
          );
        }
      }
    });
    onValidationError?.();
  }

  // Remove any old basket validation errors that were not re-added
  const currentBasketToasts = useToastStore.getState().groups['basket'] || [];
  currentBasketToasts.forEach((id) => {
    if (!toastIds.includes(id)) {
      toast.dismiss(id);
    }
  });
}
