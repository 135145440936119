import { type Mutation, type Query } from '@tanstack/react-query';

const includesMutationOrQueryKey = <T>(mutationOrQuery: Mutation<T> | Query<T>, key: string): boolean => {
  if ('queryKey' in mutationOrQuery.options) {
    return mutationOrQuery.options.queryKey?.includes(key) ?? false;
  }

  if ('mutationKey' in mutationOrQuery.options) {
    return mutationOrQuery.options.mutationKey?.includes(key) ?? false;
  }

  return false;
};

export { includesMutationOrQueryKey };
