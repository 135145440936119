import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigateWithQueryParams } from '@src/hooks/useNavigateWithQueryParams/useNavigateWithQueryParams';

import { type BasketStoreState } from '@src/store/useBasketStore';

import {
  getItemAndRemoveFunctionFromCartItemProps,
  getPropertiesFromCartItem,
  isBasketAccommodationItem,
  isBasketExtraItem,
  isBasketTicketItem,
  isBasketTransportItem,
} from './BasketCartItem.helper';
import {
  BasketAccommodationItem,
  type BasketExtraItem,
  type BasketTicketItem,
  BasketTransportItem,
} from './BasketContent.types';
import { TransportCartItem } from '@src/components/Basket/BasketContent/TransportCartItem/TransportCartItem';
import { TicketCartItem } from '@src/components/Basket/BasketContent/TicketCartItem/TicketCartItem';
import { ExtraCartItem } from '@src/components/Basket/BasketContent/ExtraCartItem/ExtraCartItem';
import { AccommodationCartItem } from '@src/components/Basket/BasketContent/AccommodationCartItem/AccommodationCartItem';

// The basket item can be either tickets or extras
export type BasketItemVariableProps =
  | {
      ticketItem: BasketTicketItem;
      removeTicketItem: (itemToRemove: BasketTicketItem) => Promise<void>;
    }
  | {
      extrasItem: BasketExtraItem;
      removeExtrasItem: (itemToRemove: BasketExtraItem) => Promise<void>;
    }
  | {
      accommodationItem: BasketAccommodationItem;
      removeAccommodationItem: (itemToRemove: BasketAccommodationItem) => Promise<void>;
    }
  | {
      transportItem: BasketTransportItem;
      removeTransportItem: (itemToRemove: BasketTransportItem) => Promise<void>;
    };

export type BasketItemProps = {
  engine: string;
  index: number;
  mode?: 'basket' | 'checkout' | 'completed';
  toggleBasket?: () => void;
  basketMutationStatus: BasketStoreState['mutateBasketStatus'];
  groupIndex?: number;
} & BasketItemVariableProps;

const BasketCartItem = ({
  engine,
  index,
  mode,
  basketMutationStatus,
  toggleBasket,
  groupIndex,
  ...variableProps
}: BasketItemProps) => {
  const [isItemBeingRemoved, setIsItemBeingRemoved] = useState<boolean>(false);

  const { item, removeItem } = getItemAndRemoveFunctionFromCartItemProps(variableProps);
  const { count } = getPropertiesFromCartItem(item);

  const { t } = useTranslation();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();

  const onEngineNameClick = () => {
    if (toggleBasket) {
      toggleBasket();
    }

    navigateWithQueryParams(`/${engine}`);
  };

  const handleRemoveItem = useCallback(async () => {
    const eCommTracking = (await import('@src/lib/tracking/eCommTracking')).default;

    setIsItemBeingRemoved(true);
    await removeItem().finally(() => {
      setIsItemBeingRemoved(false);
      if (isBasketExtraItem(item)) {
        eCommTracking.extras.sendRemoveFromCartEvent({ ...item, count });
      } else if (isBasketAccommodationItem(item)) {
        eCommTracking.accomm.sendRemoveFromCartEvent({ ...item, count });
      } else if (isBasketTicketItem(item)) {
        eCommTracking.tickets.sendRemoveFromCartEvent({ ...item, count });
      } else if (isBasketTransportItem(item)) {
        eCommTracking.transport.sendRemoveFromCartEvent({ ...item, count });
      }
    });
  }, [removeItem, item, count]);

  const getEngineTitle = (engine: string): string => {
    type TranslationKeys =
      | 'ticketsEngine:title'
      | 'extrasEngine:title'
      | 'accommodationEngine:title'
      | 'transportEngine:title';

    const engineTitleMap: { [key: string]: TranslationKeys } = {
      tickets: 'ticketsEngine:title',
      extras: 'extrasEngine:title',
      accommodation: 'accommodationEngine:title',
      transport: 'transportEngine:title',
    };

    // Get the translation key or fallback to the default
    const translationKey: TranslationKeys = engineTitleMap[engine as keyof typeof engineTitleMap];
    return t(translationKey) as string;
  };

  const engineTitle = getEngineTitle(engine);

  if ('transportItem' in variableProps) {
    return (
      <TransportCartItem
        engineTitle={engineTitle}
        item={variableProps.transportItem}
        index={index}
        mode={mode}
        onEngineNameClick={onEngineNameClick}
        basketMutationStatus={basketMutationStatus}
        removeItem={handleRemoveItem}
        isRemoving={isItemBeingRemoved}
      />
    );
  }

  if ('ticketItem' in variableProps) {
    return (
      <TicketCartItem
        engineTitle={engineTitle}
        item={variableProps.ticketItem}
        index={index}
        mode={mode}
        onEngineNameClick={onEngineNameClick}
        basketMutationStatus={basketMutationStatus}
        removeItem={handleRemoveItem}
        isRemoving={isItemBeingRemoved}
        groupIndex={groupIndex}
      />
    );
  }

  if ('extrasItem' in variableProps) {
    return (
      <ExtraCartItem
        engineTitle={engineTitle}
        item={variableProps.extrasItem}
        index={index}
        mode={mode}
        onEngineNameClick={onEngineNameClick}
        basketMutationStatus={basketMutationStatus}
        removeItem={handleRemoveItem}
        isRemoving={isItemBeingRemoved}
        groupIndex={groupIndex}
      />
    );
  }

  if ('accommodationItem' in variableProps) {
    return (
      <AccommodationCartItem
        engineTitle={engineTitle}
        item={variableProps.accommodationItem}
        index={index}
        mode={mode}
        onEngineNameClick={onEngineNameClick}
        basketMutationStatus={basketMutationStatus}
        removeItem={handleRemoveItem}
        isRemoving={isItemBeingRemoved}
      />
    );
  }

  return null;
};

export default BasketCartItem;
