/**
 * Current upper breakpoints for the app
 * Examples:
 * If screen size < SCREEN_BREAKPOINT_MOBILE = Mobile
 * If SCREEN_BREAKPOINT_MOBILE < screen size < SCREEN_BREAKPOINT_TABLET = Tablet
 * If screen size > SCREEN_BREAKPOINT_TABLET = Desktop
 */

import { BPEngineHandlesOptions, ENGINE_NAV_DATA } from './engines';

/** Product Status **/
export const STATUS_PRODUCT_AVAILABLE = 'AVAILABLE' as const;
export const STATUS_PRODUCT_OUTOFSTOCK = 'OUT_OF_STOCK' as const;
export const STATUS_PRODUCT_SOLDOUT = 'SOLD_OUT' as const;

export const FREESALE_QUANTITY = 20;

export const ERROR_PAGE = '404';

/** CDN **/
export const KABOODLE_CDN = 'https://dye1vg6h1p5m0.cloudfront.net';

/** Others */
// This is the character used to separate currency from currency code in european format
export const NON_BREAKING_SPACE = '\u00A0';

export const DEVICE_TABLET_BREAKPOINT = 768;

export const EXCLUDED_ROUTES = [ENGINE_NAV_DATA[BPEngineHandlesOptions.BOOKING_STATUS].route];

export const REFETCH_BOOKING_REF = 'refetchBookingRef';

// TODO - Double check following ID's
export const ENGINE_TICKETS_ID = 4096;
export const ENGINE_ACCOMMODATION_ID = 2;
export const ENGINE_EXTRAS_ID = 4;
export const ENGINE_LOGIN_ID = 48;
export const ENGINE_DELIVERY_ID = 20000;
export const ENGINE_PAYMENT_ID = 64;

export const HEADER_OFFSET = 40;

export enum CustomErrorCodes {
  NoTokens = 1,
}

export const INITIAL_CONTENTHEIGHT = '100vh';

/**
 * Constants for HTTP status and what they mean
 */

export enum HTTP_STATUS {
  // Success status
  Ok = 200,
  Created = 201,
  Accepted = 202,
  NoContent = 204,

  // Error status
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Gone = 410,

  // Server Errors
  InternalServerError = 500,
}

export const termsAndConditionsLink = 'https://kaboodlecdn.s3.eu-west-1.amazonaws.com/Kaboodle/terms/ticketplan.pdf';

// SL says: see https://emailregex.com/
export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum UserTypes {
  guest = 'GUEST_USER',
  existing = 'EXISTING_USER',
  pending = 'PENDING_USER',
}

export enum apiRequests {
  signIn = '/auth/signin',
  verifyEmail = '/auth/verify-email',
  forgottenPassword = '/auth/forgotten-password',
}

export enum ErrorCauses {
  EXPECTED_401 = 'Expected 401',
  UNEXPECTED_401 = 'Unexpected 401',
}

export enum StripePaymentStatus {
  SUCCESS = 'succeeded',
  PROCESSING = 'processing',
  CANCELED = 'canceled',
  REQUIRES_ACTION = 'requires_action',
  REQUIRES_CAPTURE = 'requires_capture',
  REQUIRES_CONFIRMATION = 'requires_confirmation',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
}

export enum StripeRedirectParams {
  PAYMENT_INTENT_SECRET = 'payment_intent_client_secret',
  PAYMENT_INTENT = 'payment_intent',
  REDIRECT_STATUS = 'redirect_status',
  BASKET_ID = 'b',
  CLIENT_ID = 'c',
  STRIPE_PUBLIC_KEY = 'stripePublicKey',
  GTM_IDS = 'gtmIds',
  META_IDS = 'metaIds',
}

export enum BookingBasketStatus {
  COMPLETED = 'COMPLETED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETING = 'COMPLETING',
}

export enum PaymentTypeHandle {
  FULL = 'FULL',
  DEPOSIT = 'DEPOSIT',
  PAYMENT_PLAN = 'PAYMENT_PLAN',
  ZERO_VALUE = 'ZERO_VALUE',
}

// UK Country Names

export type TYPE_UK_COUNTRY_NAMES = {
  england: 'england';
  scotland: 'scotland';
  wales: 'wales';
  northernIreland: 'Northern Ireland';
  unitedKingdom: 'United Kingdom';
};
/**
 * List of UK Country names lower case
 */
export const UK_COUNTRY_NAMES: TYPE_UK_COUNTRY_NAMES = {
  england: 'england',
  scotland: 'scotland',
  wales: 'wales',
  northernIreland: 'Northern Ireland',
  unitedKingdom: 'United Kingdom',
};

export const RESTART_ROUTE = '/restart';
export const HOME_ROUTE = '/';

export const MAXIMUM_REFETCH_BOOKING_STATUS = 12;
export const MAXIMUM_REFETCH_STRIPE_INTENT = 10;

// Meta tracking
export const FBC_COOKIE_NAME = '_fbc';
export const KABOODLE_FALLBACK_FBC_COOKIE_NAME = '_kaboodle_fbc';
export const CLICK_ID_METADATA_KEY = 'fbClickId';

// Tab Store
export enum SelectedEngineFilterParams {
  EVENT_ID = 'eventId',
  ACCOMMODATION_GROUP_ID = 'accommodationGroupId',
  EXTRA_TYPE_ID = 'extraTypeId',
  TRANSPORT_TYPE = 'transportType',
  ACCOMMODATION_IDS = 'acommodationIds',
}

// DB character limit - DB will truncate saved data over the limits listed below as of 15/01/25
export const EXTRA_INFO_CHAR_LIMIT = 100;

export const ADDRESS_1_CHAR_LIMIT = 100;
export const ADDRESS_2_CHAR_LIMIT = 100;
export const ADDRESS_POSTAL_CHAR_LIMIT = 10;
export const ADDRESS_CITY_CHAR_LIMIT = 20;

export const DISCOUNT_CHAR_LIMIT = 80;
// Ticket holder and Lead customer have the same limit
export const CUSTOMER_FIRST_NAME_CHAR_LIMIT = 30;
export const CUSTOMER_LAST_NAME_CHAR_LIMIT = 35;
export const CUSTOMER_EMAIL_CHAR_LIMIT = 60;
export const CUSTOMER_TELEPHONE_CHAR_LIMIT = 24;

export const PASSWORD_CHAR_MIN = 8;
export const PASSWORD_CHAR_LIMIT = 64;
