import { MouseEventHandler, useCallback, useEffect, useMemo } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Typography } from '@kaboodle-solutions/design-library';
import { DEVICE_TABLET_BREAKPOINT, EXCLUDED_ROUTES } from '@src/constants';
import useScreenBreakpoint from '@src/hooks/useScreenBreakpoint';
import { useBasketStore } from '@src/store/useBasketStore';
import tracking from '@src/lib/tracking';

import styles from './navigationStepper.module.scss';
import { type Step, useEngineNavigationStore } from '@src/store/useEngineNavigationStore';
import { BasketExpireTimer } from '../BasketExpireTimer';
import { LinkWithQueryParams } from '../LinkWithQueryParams/LinkWithQueryParams';

const NavigationStepper = () => {
  const location = useLocation();
  const { mutateBasketStatus } = useBasketStore();
  const { steps } = useEngineNavigationStore();

  const shouldRender = useMemo(() => !EXCLUDED_ROUTES.includes(location.pathname), [location]);

  if (!shouldRender) {
    return null;
  }

  return (
    <div className={styles.navigationStepperWrapper}>
      <div className={styles.navStepperAndTimerContainer}>
        <nav className={styles.navigationStepper} aria-label="NavigationStepper">
          <ol className={styles.navigationStepperList}>
            {steps.map((step, index) => (
              <Step mutateBasketStatus={mutateBasketStatus} key={step.linkUrl} step={step} index={index + 1} />
            ))}
          </ol>
        </nav>
        <BasketExpireTimer />
      </div>
    </div>
  );
};

type StepProps = {
  step: Step;
  index: number;
  mutateBasketStatus: 'error' | 'success' | 'pending' | 'idle';
};

export function addSpacesToCamelCase(value: string) {
  return value.replace(/([A-Z])/g, ' $1').trim();
}

function Step({ step, index, mutateBasketStatus }: StepProps) {
  const { pathname } = useLocation();
  const { t } = useTranslation(['navigation']);
  const { screenWidth } = useScreenBreakpoint();

  const isOnMobile = screenWidth < DEVICE_TABLET_BREAKPOINT;
  const isActive = pathname === step.linkUrl || pathname.includes(step.linkUrl);
  const content = isActive ? addSpacesToCamelCase(t(step.engineName as unknown as never)) : index;

  const shouldNavigate: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      if (step.enableNavigation && mutateBasketStatus !== 'pending') {
        window.scrollTo(0, 0);
      } else {
        event.preventDefault();
      }
    },
    [step, mutateBasketStatus]
  );

  useEffect(() => {
    if (isActive) tracking.locationId = index;
  }, [isActive, index]);

  return (
    <li
      key={step.linkUrl}
      data-testid={`navstepper-active-item-${index}`}
      className={classNames(styles.navLinkItem, {
        [styles.navLink_active]: isActive,
        [styles.navLink_disabled]: !step.enableNavigation || mutateBasketStatus === 'pending',
      })}
    >
      <LinkWithQueryParams
        to={step.linkUrl}
        data-testid={`navigation-step-${index}`}
        onClick={shouldNavigate}
        title={!isActive ? String(t(`navigation:${step.engineName}` as unknown as never)) : undefined}
        className={classNames(styles.navLink, {
          [styles.navLink_caption]: isOnMobile,
        })}
      >
        <Typography tag="span" type="subtext">
          {content}
        </Typography>
      </LinkWithQueryParams>
    </li>
  );
}

export default NavigationStepper;
