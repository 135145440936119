import { createAndAddCssLink } from './useSelectClientTheme.helper';
import { useEffect, useRef } from 'react';

import useBpTokenStore from '@src/store/useBpTokenStore';
import { useSearchParams } from 'react-router-dom';
import { useStripeReturnUrlStore } from '@src/store/persistent/useStripeReturnUrlStore';
import { getCdnUrlFromEnvironment } from '@src/lib/getCdnUrlFromEnvironment';
import useInitResponseStore from '@store/persistent/initResponse/useInitResponseStore';

export const useSelectClientTheme = () => {
  const [searchParams] = useSearchParams();
  const { c: clientIDFromStripeReturnUrl } = useStripeReturnUrlStore();
  const cdnBaseUrl = getCdnUrlFromEnvironment();
  const { client } = useInitResponseStore(({ client }) => ({ client }));

  const { bpTokenExtractedUnverified } = useBpTokenStore();
  const clientIdFromParams = searchParams.get('c');
  const previousClientIdRef = useRef<string | null>(null);

  useEffect(() => {
    const clientId =
      (clientIdFromParams || clientIDFromStripeReturnUrl || bpTokenExtractedUnverified?.c || null)?.toString() || null;
    const lookupCode = client?.lookupCode;

    // If we're switching clients, clean up old CSS
    if (previousClientIdRef.current && previousClientIdRef.current !== clientId) {
      const existingFontCss = document.querySelector('#font-declaration');
      const existingThemeCss = document.querySelector('#theme-declaration');

      if (document.head.contains(existingFontCss) && existingFontCss) {
        document.head.removeChild(existingFontCss);
      }
      if (document.head.contains(existingThemeCss) && existingThemeCss) {
        document.head.removeChild(existingThemeCss);
      }
    }

    if (clientId && lookupCode) {
      // Add new CSS links
      createAndAddCssLink('font', lookupCode, `https://dye1vg6h1p5m0.cloudfront.net/${lookupCode}/fonts/font-face.css`);
      createAndAddCssLink('theme', lookupCode, `${cdnBaseUrl}/BP/${clientId}/theme.css`);
    }

    previousClientIdRef.current = clientId;
  }, [bpTokenExtractedUnverified?.c, clientIdFromParams, clientIDFromStripeReturnUrl, cdnBaseUrl, client?.lookupCode]);
};
