export type TransportTypeRoute = {
  id: number;
  name: string;
  description: string;
  type: TransportTypeOption;
};

export type TransportType = {
  handle: string;
  name: string;
  available: boolean;
  oneWay: boolean;
  routes: TransportTypeRoute[];
};

export enum TransportTypeOption {
  COACH = 'COACH',
  CYCLE = 'CYCLE',
  FLIGHT = 'FLIGHT',
  FLTBKD = 'FLTBKD',
  NOTRANSP = 'NOTRANSP',
  SHUTTLE = 'SHUTTLE',
  TRAIN = 'TRAIN',
  TRANSFER = 'TRANSFER',
  TRANSTRA = 'TRANSTRA',
}

export enum TransportDirectionOption {
  OUTBOUND = 'OUTBOUND',
  RETURN = 'RETURN',
  BOTH = 'BOTH',
  OUTBOUND_WITH_RETURN = 'OUTBOUND_WITH_RETURN', // Outbound, only with corresponding return stops
}

export type TransportDate = string;

export type KapiPackageTransportDate = {
  date: TransportDate;
  available: boolean;
};

export type KapiPackageTransportLocation = {
  id: number;
  name: string;
  direction?: string;
  arrivalLocations?: KapiPackageTransportLocation[];
  externalNotes?: string;
};

export type TransportTime = string;

export type KapiTransportStopCurrency = {
  code: string;
  symbol: string;
  decimalPlaces: number;
  formatted: string;
};

export type PackageTransportStopPrice = {
  value: number;
  bookingFee?: number;
};

export type PackageTransportStop = {
  stopId: number;
  routeId: number;
  date: TransportDate;
  time: TransportTime;
  price: PackageTransportStopPrice;
  direction: TransportDirectionOption;
  enabled: boolean;
  available: number;
  dynamic?: number;
  freesale: boolean;
};
