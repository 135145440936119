import { AxiosError, isAxiosError, type AxiosResponse } from 'axios';
import { captureException, withScope } from '@sentry/react';
import { ReactQueryError } from '@src/lib/basketNotificationHandling/ReactQueryError';

type SentryReportingOptions = {
  customErrorHandler?: (AxiosError: AxiosError) => void;
  skipErrorResponsesReporting?: number[];
  skipAllReporting?: boolean;
  additionalContext?: Record<string, unknown>;
};

export function axiosRequestWithSentryReporting<Args extends unknown[], ReturnType>(
  apiCall: (...args: Args) => Promise<AxiosResponse<ReturnType>>,
  options: SentryReportingOptions = {}
) {
  return async (...args: Args) => {
    return await apiCall(...args).catch((error) => {
      if (isAxiosError(error)) {
        if (options.customErrorHandler) {
          options.customErrorHandler(error);
        }

        if (
          error.response?.status &&
          !options.skipErrorResponsesReporting?.includes(error.response.status) &&
          !options.skipAllReporting
        ) {
          withScope((scope) => {
            const endpoint = error.config?.url || 'unknown';
            scope.setExtra('endpoint', endpoint);
            scope.setExtra('method', error.config?.method);
            scope.setContext('Axios Error', { name: error.name, message: error.message });
            scope.setTransactionName(`Axios Error: ${endpoint} [${error.response?.status}]`);
            if (options.additionalContext) {
              Object.entries(options.additionalContext).forEach(([key, value]) => {
                scope.setExtra(key, value);
              });
            }
            scope.setTag('endpoint', endpoint);
            // https://docs.sentry.io/platforms/javascript/enriching-events/fingerprinting/
            // TLDR
            // default grouping for issues
            // Then grouped by endpoint for issues
            // Then grouped by status code in issues
            scope.setFingerprint(['{{ default }}', endpoint, String(error.response?.status)]);
            captureException(error);
          });
        }

        // We need to throw an error otherwise react-query wont catch it in case of api call failure
        throw new ReactQueryError(error, true);
      } else {
        captureException(error);
        throw error;
      }
    });
  };
}
